import React,{useState} from 'react'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import CryptoJS from 'crypto-js';
import {myGlobal,api} from '../Global'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'



export default function Changepass() {
 const history=useNavigate();
const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

    const [oldusername, setOldusername] = useState();
      const [olduseractive, setOlduseractive] = useState("0");


    const [oldpassword, setOldpassword] = useState();
    const [oldpassactive, setOldpassactive] = useState("0");

        const [newusername, setNewusername] = useState();
      const [newuseractive, setNewuseractive] = useState("0");

    const [newpassword, setNewpassword] = useState();
    const [newpassactive, setNewpassactive] = useState("0");

    const [showloading, setshowLoading] = useState(false);
    
     const changeoldusername = (e) => {       
             
            if(e.target.value.length >3)
            {
               setOlduseractive("2")
            }  
            else{
                setOlduseractive("1")
            }              
            setOldusername(e.target.value)
        }

          const changeoldpassword = (e) => {        
             
              if(e.target.value.length >3)
            {
               setOldpassactive("2")
            }  
            else{
                setOldpassactive("1")
            } 
            setOldpassword(e.target.value)           
        }


             const changenewusername = (e) => {       
             
             if(e.target.value.length >3)
            {
               setNewuseractive("2")
            }  
            else{
                setNewuseractive("1")
            }              
            setNewusername(e.target.value)
        }

          const changenewpassword = (e) => {        
             
             if(e.target.value.length >3)
            {
               setNewpassactive("2")
            }  
            else{
                setNewpassactive("1")
            } 
            setNewpassword(e.target.value)           
        }


          const  changepass = async() => { 


           
        
          if(olduseractive==="2" &&  oldpassactive ==="2" && newuseractive==="2" &&  newpassactive ==="2"  )     
          {
            
            setshowLoading(true)
                

                let bytes  = CryptoJS.AES.decrypt(localStorage.getItem("token"), myGlobal);
        
            let authorization=bytes.toString(CryptoJS.enc.Utf8);    
            authorization="Bearer "+authorization;
                
             

                                fetch(api+"/changepass.php",            {
                        method: 'POST',   
                    
                        headers: {
                            'Accept': 'application/json',
                            
                            'Authorization': authorization
                        },
                        body: JSON.stringify({oldusername: oldusername,
                           oldpassword:oldpassword,
                             newusername: newusername,
                           newpassword:newpassword,

                          
                          })
                        }).then((Response) => Response.text())
                        .then((Result) => {
                         
                              Result=JSON.parse(Result)

                               try{


                                  if(Result.message==="success")
                                {
                                         setshowLoading(false)

                                           Toast.fire({
                        icon: 'success',
                        title: 'Password Changed Successfully!'
                      })

                                      
                                        
                            
                                }
                                else if(Result.message==="invalid"){
                                            setshowLoading(false)

                                              Toast.fire({
                        icon: 'error',
                        title: 'Invalid Username or Password!'
                      })

                                        
                                }
                                else if(Result.data.message==='"error"'){
                                            setshowLoading(false)
                                        

                                        alert("Something Went wrong. Please try again later!");
                                }

                          if(Result.error==="Access denied")  
                          {
                              history('../logout')
                          }

                        }
                        catch(f)
                        {
                             
                              
                              }
                            

                                      setshowLoading(false)
                        
                        })


          }
          else{
        
          if(olduseractive==="0")
          {
            setOlduseractive("1");
          }

          if(oldpassactive==="0")
          {
            setOldpassactive("1");
          }

            if(newuseractive==="0")
          {
            setNewuseractive("1");
          }

           if(newpassactive==="0")
          {
            setNewpassactive("1");
          }

          
  setshowLoading(false)

          }


        }


          React.useEffect(() => 
  {
     document.title = "Chnage Password"
     //console.log(JSON.stringify(location))
     document.body.classList.remove('toggle-sidebar')  
      
   
  }, [])

    return (
       
       <>

 <Header isLoading={showloading} />

 <Sidebar/>
    {/* <!-- Main content --> */}
    <main id="main" className="main mainremoveback">

    <div className="pagetitle">
      <h1>Change Password</h1>
      <nav>
        <ol className="breadcrumb">
         
          <li className="breadcrumb-item active">Change Password</li>
        </ol>
      </nav>
    </div>


    <section className="section dashboard">

   
<div class="card">

  
         
            <div class="card-body">
             <div class="row g-3 mt-3">
                
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                         <label for="inputName5" class="form-label"><strong>Current Username </strong> </label>
                        <input type="text"  value={oldusername} className={olduseractive==='0' ? 'form-control' : olduseractive==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }  onChange={(e) => {changeoldusername(e)}}  class="form-control" placeholder="Username"  />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="inputName5" class="form-label"><strong>Current Password </strong> </label>
                        <input type="password" value={oldpassword} className={oldpassactive==='0' ? 'form-control' : oldpassactive==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }  onChange={(e) => {changeoldpassword(e)}} class="form-control" placeholder="Password" />
                      </div>
                    </div>
                  </div>
                
                </div>
              
               
                <div class="pl-lg-4">
                 <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                            <label for="inputName5" class="form-label"><strong>New Username </strong> </label>
                        <input type="text" value={newusername} className={newuseractive==='0' ? 'form-control' : newuseractive==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }  onChange={(e) => {changenewusername(e)}} class="form-control" placeholder="Username"   />
                      </div>

                      

                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                       <label for="inputName5" class="form-label"><strong>New Password </strong> </label>
                        <input type="password" value={newpassword} className={newpassactive==='0' ? 'form-control' : newpassactive==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }  onChange={(e) => {changenewpassword(e)}} class="form-control" placeholder="Password"  />
                      </div>
                    </div>
                  </div>



                      <div class="row">
                    <div class="col-lg-6">
                    
                        <div class="form-group">
                        <button class="btn btn-primary" style={{marginTop:'1em'}} type="button"  onClick={changepass}>Change
                        
                          {showloading ?
                    <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" ></span>
                   :null
                   }   
                        </button>

                      </div>

                    </div>
                    <div class="col-lg-6">
                     
                    </div>
                  </div>
                </div>
          
              </div>
            </div>
        
     
      </div>
       
       
        
        </section>


        </main>


       </>
    )
}

