import React,{useState,useRef} from 'react'
 import {myGlobal,api} from '../Global'

import CryptoJS from 'crypto-js';
import { useNavigate , useLocation } from 'react-router-dom'
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import axios from 'axios';
import Swal from 'sweetalert2'
import JoditEditor from "jodit-react";
import qs from 'qs';
 import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

export default function Editblog() {

     const location = useLocation();
       const history=useNavigate();
  

      const [mytitle]=useState("Edit Blog");

  
const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

          const [showloading,setshowLoading]=useState(false);
        const [selectvalue, setSelectvalue] = useState(0);


         
 const editor = useRef(null);
          
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: 400
  };

              const changestatus = (e) => {
            setSelectvalue(e.target.value)
            
        }

         const [name, setName] = useState();
    const [nameactive, setNameactive] = useState("0");

            const changename = (e) => {        
             
            if(e.target.value!=="" && e.target.value.length>1)
            { 
                /* return true */
                    setNameactive("2")
            }
            else{
                    setNameactive("1")
            }

            setName(e.target.value)
        }

        
        



             const [setprogress, setsetprogress] = useState(0);
  let fileprocess=0;
         const  registerme1 = async() => {

          if(content==="<p><br></p>" || content==="")
          {
             Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: "Description Required",
                                   
                                  })

                                  return
          }
           

           
      setshowLoading(true)
          
         
             

          if(nameactive==="2" &&   fileupload1active==="2" ) 
          {

      
           setshowLoading(true)
                        
                      
            

       let bytes  = CryptoJS.AES.decrypt(localStorage.getItem("token"), myGlobal);
  
      let authorization=bytes.toString(CryptoJS.enc.Utf8);    
       authorization="Bearer "+authorization;
          
         

            
             
            // console.log(authorization)
         
         axios.post(api+"/updateblog.php",
                      JSON.stringify({id:location.state.lginid ,title: name, description: content,img:filebase641,img1:filebase642,img2:filebase643,img3:filebase644}),
                      {
                        headers: {
                             'Accept': 'application/json',                         
                           'Authorization': authorization          
                      },
                       onUploadProgress : (progressEvent) => {
      let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      // Update state here
     
          setsetprogress(progress);
  
       fileprocess=progress;
    },
                      
      })
      .then(response => {
                          
          
          // alert(JSON.stringify(response))

           try{


               if(response.data.message==='success')
                    {

                       // document.getElementById("bank1").value="";      
                        // document.getElementById("birthdaytime").focus()           

                          
                       
                        Toast.fire({
                        icon: 'success',
                        title: 'Blog Updated successfully'
                      })
                      
                      history("../bloglist")
                    }
                    else if(response.message==='error'){

                           
                               Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: "Something went wrong. Please try agai later!",
                                   
                                  })

                    }

                          if(response.data.message==="Access denied")  
                          {
                              history('../logout')
                          }

                        }
                        catch(f)
                        {

                        }
                
          
                 
                    // }
                         
                  
                           
                      
                          setshowLoading(false)

                    


      })
      .catch(function (error) {
          setshowLoading(false)
               setshowLoading(false)

               alert(error)
                 
       
      });

    }
    else{

        if(nameactive==="0")
          {
            setNameactive("1");
          }

          

           if(fileupload1active==="0")
          {
            setfileupload1active("1");
          }
            

    }


        }


        const [imgurls, setimgurls] = useState("");
         const [imgurls2, setimgurls2] = useState("");
          const [imgurls3, setimgurls3] = useState("");
           const [imgurls4, setimgurls4] = useState("");

            const [fileext, setfileext] = useState("");
               const [fileupload1active, setfileupload1active] = useState("2");
    const [filebase641, setfilebase641] = useState("../assets/img/user_icon.png");

            const fileChangedHandler1 = (e) => {   
            let file_size = e.target.files[0].size;
            let file_name = e.target.files[0].type;
             
            let getinfo=e.target.files[0].name.split('.')
           
          

            if(getinfo[1]==="png" || getinfo[1]==="jpg" || getinfo[1]==="jpeg")
            {

              setfileext(getinfo[1])
              setfileupload1active("2")
           

          // let blobURL = URL.createObjectURL(e.target.files[0]);

           //document.querySelector("myvideo").src = blobURL;

          
           // document.getElementById("myvideo").src= blobURL;

          
            if((parseInt(file_size)/1024) < 20000)
            {

              setfileupload1active("2")

                 var fileToLoad = e.target.files[0];

                // seturl(e.target.files[0])
                
                 //alert(String(e.target.files[0].value))
               var fileReader = new FileReader();
           
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {          
              
              
                setfileupload1active("2")
                setfilebase641(fileLoadedEvent.target.result)
                // Print data in console
              
            };
            // Convert data to base64
              fileReader.readAsDataURL(fileToLoad);
            }
            else{
                setfileupload1active("1")
            }


             }
            else{

              setfileupload1active("1")
              return
            }
     
        }


          const [fileext2, setfileext2] = useState("");
               const [fileupload2active, setfileupload2active] = useState("2");
    const [filebase642, setfilebase642] = useState("../assets/img/user_icon.png");

            const fileChangedHandler2 = (e) => {   
            let file_size = e.target.files[0].size;
            let file_name = e.target.files[0].type;
             
            let getinfo=e.target.files[0].name.split('.')
           
          

            if(getinfo[1]==="png" || getinfo[1]==="jpg" || getinfo[1]==="jpeg")
            {

              setfileext2(getinfo[1])
              setfileupload2active("2")
           

          // let blobURL = URL.createObjectURL(e.target.files[0]);

           //document.querySelector("myvideo").src = blobURL;

          
           // document.getElementById("myvideo").src= blobURL;

          
            if((parseInt(file_size)/1024) < 20000)
            {

              setfileupload2active("2")

                 var fileToLoad = e.target.files[0];

                // seturl(e.target.files[0])
                
                 //alert(String(e.target.files[0].value))
               var fileReader = new FileReader();
           
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {          
              
              
                setfileupload2active("2")
                setfilebase642(fileLoadedEvent.target.result)
                // Print data in console
              
            };
            // Convert data to base64
              fileReader.readAsDataURL(fileToLoad);
            }
            else{
                setfileupload2active("1")
            }


             }
            else{

              setfileupload2active("1")
              return
            }
     
        }


                  const [fileext3, setfileext3] = useState("");
               const [fileupload3active, setfileupload3active] = useState("2");
    const [filebase643, setfilebase643] = useState("../assets/img/user_icon.png");

            const fileChangedHandler3 = (e) => {   
            let file_size = e.target.files[0].size;
            let file_name = e.target.files[0].type;
             
            let getinfo=e.target.files[0].name.split('.')
           
          

            if(getinfo[1]==="png" || getinfo[1]==="jpg" || getinfo[1]==="jpeg")
            {

              setfileext3(getinfo[1])
              setfileupload3active("2")
           

          // let blobURL = URL.createObjectURL(e.target.files[0]);

           //document.querySelector("myvideo").src = blobURL;

          
           // document.getElementById("myvideo").src= blobURL;

          
            if((parseInt(file_size)/1024) < 20000)
            {

              setfileupload3active("2")

                 var fileToLoad = e.target.files[0];

                // seturl(e.target.files[0])
                
                 //alert(String(e.target.files[0].value))
               var fileReader = new FileReader();
           
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {          
              
              
                setfileupload3active("2")
                setfilebase643(fileLoadedEvent.target.result)
                // Print data in console
              
            };
            // Convert data to base64
              fileReader.readAsDataURL(fileToLoad);
            }
            else{
                setfileupload3active("1")
            }


             }
            else{

              setfileupload2active("1")
              return
            }
     
        }

              const [fileext4, setfileext4] = useState("");
               const [fileupload4active, setfileupload4active] = useState("2");
    const [filebase644, setfilebase644] = useState("../assets/img/user_icon.png");

            const fileChangedHandler4 = (e) => {   
            let file_size = e.target.files[0].size;
            let file_name = e.target.files[0].type;
             
            let getinfo=e.target.files[0].name.split('.')
           
          

            if(getinfo[1]==="png" || getinfo[1]==="jpg" || getinfo[1]==="jpeg")
            {

              setfileext4(getinfo[1])
              setfileupload4active("2")
           

          // let blobURL = URL.createObjectURL(e.target.files[0]);

           //document.querySelector("myvideo").src = blobURL;

          
           // document.getElementById("myvideo").src= blobURL;

          
            if((parseInt(file_size)/1024) < 20000)
            {

              setfileupload4active("2")

                 var fileToLoad = e.target.files[0];

                // seturl(e.target.files[0])
                
                 //alert(String(e.target.files[0].value))
               var fileReader = new FileReader();
           
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {          
              
              
                setfileupload4active("2")
                setfilebase644(fileLoadedEvent.target.result)
                // Print data in console
              
            };
            // Convert data to base64
              fileReader.readAsDataURL(fileToLoad);
            }
            else{
                setfileupload4active("1")
            }


             }
            else{

              setfileupload4active("1")
              return
            }
     
        }


        




                   const  getmenudata = async(menuid) => { 

        setshowLoading(true)

      let bytes  = CryptoJS.AES.decrypt(localStorage.getItem("token"), myGlobal);
  
      let authorization=bytes.toString(CryptoJS.enc.Utf8);

       authorization="Bearer "+authorization;

    

       fetch(api+"/bloglistsingle.php",  {
                  method: 'POST',   
               
                  headers: {
                    'Accept': 'application/json',     
                    'Authorization': authorization
                  },
                    body: JSON.stringify({id: menuid})
                
                }).then((response) => response.json())
                  .then((response) => {
                          
                    
                      try{
                          
                          setName(response[0].head)
                        setNameactive("2")

                        setContent(response[0].descs)

                        
                        setimgurls(response[0].img)
                        setimgurls2(response[0].img2)
                        setimgurls3(response[0].img3)
                        setimgurls4(response[0].img4)
                          if(response.error==="Access denied")  
                          {
                              history('../logout')
                          }

                        }
                        catch(f)
                        {

                       

                        }

                        setshowLoading(false)
       
                  }).catch(error => {
                         setshowLoading(false)
                         
                             alert(error)
                           
                })

    }

         
      const deletes = () => {                       

         confirmAlert({
      title: 'Confirm to Delete',
      message: location.state.manager,
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteblog()
        },
        {
          label: 'No',
         
        }
      ]
    });
  }

           const  deleteblog = async() => {

                    

           
      setshowLoading(true)
          
         
             

          if(nameactive==="2" ) 
          {

      
           setshowLoading(true)
                        
                      
            

       let bytes  = CryptoJS.AES.decrypt(localStorage.getItem("token"), myGlobal);
  
      let authorization=bytes.toString(CryptoJS.enc.Utf8);    
       authorization="Bearer "+authorization;
          
         
         axios.post(api+"/deleteblog.php",
                      JSON.stringify({id:location.state.lginid}),
                      {
                        headers: {
                             'Accept': 'application/json',                         
                           'Authorization': authorization          
                      },
                       onUploadProgress : (progressEvent) => {
      let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      // Update state here
     
          setsetprogress(progress);
  
       fileprocess=progress;
    },
                      
      })
      .then(response => {
                          
          
          // alert(JSON.stringify(response))

           try{


               if(response.data.message==='success')
                    {

                       // document.getElementById("bank1").value="";      
                        // document.getElementById("birthdaytime").focus()           

                          
                       
                        Toast.fire({
                        icon: 'success',
                        title: 'Blog Deleted successfully'
                      })
                      
                      history("../bloglist")
                    }
                    else if(response.message==='error'){

                           
                               Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: "Something went wrong. Please try agai later!",
                                   
                                  })

                    }

                          if(response.data.message==="Access denied")  
                          {
                              history('../logout')
                          }

                        }
                        catch(f)
                        {

                        }
                
          
                 
                    // }
                         
                  
                           
                      
                          setshowLoading(false)

                    


      })
      .catch(function (error) {
          setshowLoading(false)
               setshowLoading(false)

               alert(error)
                 
       
      });

    }
    else{

        if(nameactive==="0")
          {
            setNameactive("1");
          }

          

       
            

    }


        }

   React.useEffect(() => 
  {      
    document.title = mytitle + " | BLP "



    getmenudata(location.state.lginid)

    // getmenudata(location.state.lginid)
    
  }, [])

    
  return (

            <>

                              {showloading ?
       
       <>
        <div style={{
    background: '#000000b3',
    position: 'absolute',
    top: '0',
    left: '0',
    textAlign:'center',
    width: '100%',
    height: '220vh',
    zIndex: '1000'
 

}}>

<div style={{height:'100px',marginTop: '20%',textAlign:'center'}}>
<img src='../assets/img/fileupload.gif' style={{height:'100px'}}  alt='s' /><br/>
<span style={{color:'#fff',width:'100px',height:'100px',marginTop: '20%'}} > {setprogress} %</span> <br/> <br/>

<span style={{color:'#dedede',width:'100px',height:'100px',marginTop: '20%',fontSize:'small'}} > Please wait.....</span> <br/>

</div>

</div>
       </>
         :null}

            <Header/>
            <Sidebar/>
       <main id="main" className="main mainremoveback">

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">{mytitle}</h5>

             
             
                <div className="row mb-3">
                  <label for="inputText" className="col-sm-2 col-form-label">Title</label>
                  <div className="col-sm-8">
                        <input type="text" value={name} maxLength={150} className={nameactive==='0' ? 'form-control' : nameactive==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }  onChange={(e) => {changename(e)}}    class="form-control" placeholder="Enter Name"  />

                  </div>
                </div>


              
                        <div className="row mb-3">

                  <label className="col-sm-2 col-form-label">Select Image</label>
                  <div className="col-sm-4">
                  <input class="form-control" type="file" id="bank1"    accept="image/png,image/jpeg"
  
  className={fileupload1active==='0' ? 'form-control' : fileupload1active==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }
    onChange={(e) =>fileChangedHandler1(e)}   
    style={{maxWidth:'250px'}}/>

     
      <img src={api+"/"+imgurls} alt="Current Image URL" style={{maxWidth:'150px'}} />
                  </div>
                 
                </div>

                         <div className="row mb-3">

                  <label className="col-sm-2 col-form-label">Select Image 2</label>
                  <div className="col-sm-4">
                  <input class="form-control" type="file" id="bank2"    accept="image/png,image/jpeg"
  
  className={fileupload2active==='0' ? 'form-control' : fileupload2active==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }
    onChange={(e) =>fileChangedHandler2(e)}   
    style={{maxWidth:'250px'}}/>
        <br/>
            <button type="submit" className="btn btn-danger" onClick={() =>   { setfilebase642("../assets/img/delete.png")
             setimgurls2("") } }   
                   >  Delete Image</button>
                     <br/>
      <img src={api+"/"+imgurls2} alt="Current Image URL" style={{maxWidth:'150px'}} />
                  </div>
                 
                </div>

                        <div className="row mb-3">

                  <label className="col-sm-2 col-form-label">Select Image 3</label>
                  <div className="col-sm-4">
                  <input class="form-control" type="file" id="bank3"    accept="image/png,image/jpeg"
  
  className={fileupload3active==='0' ? 'form-control' : fileupload3active==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }
    onChange={(e) =>fileChangedHandler3(e)}   
    style={{maxWidth:'250px'}}/>
  <br/>
             <button type="submit" className="btn btn-danger" onClick={() =>
             { setfilebase643("../assets/img/delete.png")
             setimgurls3("") }
              }   
                   > Delete Image</button>
       <br/>
      <img src={api+"/"+imgurls3} alt="Current Image URL" style={{maxWidth:'150px'}} />
                  </div>
                 
                </div>

                  <div className="row mb-3">

                  <label className="col-sm-2 col-form-label">Select Image 4</label>     
               
                  <div className="col-sm-4">
                  <input class="form-control" type="file" id="bank4"    accept="image/png,image/jpeg"
  
  className={fileupload3active==='0' ? 'form-control' : fileupload3active==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }
    onChange={(e) =>fileChangedHandler4(e)}   
    style={{maxWidth:'250px'}}/>
  <br/>
       <button type="submit" className="btn btn-danger" onClick={() =>   { setfilebase644("../assets/img/delete.png")
             setimgurls4("") } }   
                   > Delete Image</button>
  <br/>
     
      <img src={api+"/"+imgurls4} alt="Current Image URL" style={{maxWidth:'150px'}} />
                  </div>
                 
                </div>


           

   <div className="row mb-3">
                  <label for="inputText" className="col-sm-2 col-form-label">Blog</label>
                    <JoditEditor
        ref={editor}
        value={content}
        config={config}
       // onBlur={handleUpdate}
        onBlur={(newContent) => { setContent(newContent)}}
      />
      </div>
                

                <div className="row mb-3">
                  <label className="col-sm-1 col-form-label">
                    <button type="submit" className="btn btn-primary" onClick={registerme1} ><i class="bx bxs-save"></i> Save</button> </label>

                         <label className="col-sm-2 col-form-label">
                      <button type="submit" className="btn btn-danger" onClick={deletes} ><i class="bx bxs-save"></i> Delete</button>
                      </label> 
                  <div className="col-sm-6">
                  
                  </div>
                </div>



             
            </div>
          </div>

        </div>

     
      </div>
    </section>

  </main>
</>
  )
}
