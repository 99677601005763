import React,{useState} from 'react'
import { Link,useNavigate } from 'react-router-dom';
 import CryptoJS from 'crypto-js';
  import {myGlobal} from '../Global'

export default function Sidebar() {

    const history = useNavigate();

    const [hdid, sethdid] = useState();

    React.useEffect(() => 
  {
    // sethdid(CryptoJS.AES.decrypt(localStorage.getItem("hdid"), myGlobal).toString(CryptoJS.enc.Utf8))
  
  }, [history])

    return (
        <>

   

  <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item">
        <Link className="nav-link " to="../dashboard">
          <i className="bi bi-grid"></i>
          <span>Dashboard</span>
        </Link>
      </li>

       <li className="nav-item">
        <Link className="nav-link " to="../bloglist">
          <i className="bi bi-grid"></i>
          <span>Blog</span>
        </Link>
      </li>
        <li className="nav-item">
        <Link className="nav-link " to="../careerlist">
          <i className="bi bi-grid"></i>
          <span>Career</span>
        </Link>
      </li>
{/* 
      <li className="nav-item">
        <Link className="nav-link " to="../orderdashboard">
          <i className="bi bi-grid"></i>
          <span>Order Dashboard -Check</span>
        </Link>
      </li>


              <li className="nav-item">
        <Link className="nav-link collapsed" data-bs-target="#users-nav" data-bs-toggle="collapse" to="#" aria-expanded="false">
          <i className="bi bi-people"></i><span>Master</span><i className="bi bi-chevron-down ms-auto"></i>
        </Link>
        <ul id="users-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">

        <li>
            <Link to="../menulist">
              <i className="bi bi-circle"></i><span>Add Menu</span>
            </Link>
          </li>            


           <li>
            <Link to="../submenulist">
              <i className="bi bi-circle"></i><span>Add Sub Category</span>
            </Link>
          </li>           

           <li>
            <Link to="../agegrouplist">
              <i className="bi bi-circle"></i><span>Age Group</span>
            </Link>
          </li>    

         <li>
            <Link to="../brandlist">
              <i className="bi bi-circle"></i><span>Brand</span>
            </Link>
          </li>          


            <li>
            <Link to="../tax">
              <i className="bi bi-circle"></i><span>Tax</span>
            </Link>
          </li>         


        </ul>
      </li>



       <li className="nav-item">
          <Link className="nav-link collapsed" data-bs-target="#users-nav2" data-bs-toggle="collapse" to="#" aria-expanded="false">
        <i className="bi bi-people"></i><span>User Management</span><i className="bi bi-chevron-down ms-auto"></i>
        </Link>
        <ul id="users-nav2" className="nav-content collapse" data-bs-parent="#sidebar-nav">

        <li>
            <Link to="../userlist">
              <i className="bi bi-circle"></i><span>User List</span>
            </Link>
        </li>           
        </ul>
      </li>

        <li className="nav-item">
          <Link className="nav-link collapsed" data-bs-target="#users-nav1" data-bs-toggle="collapse" to="#" aria-expanded="false">
        <i className="bi bi-people"></i><span>Product Management</span><i className="bi bi-chevron-down ms-auto"></i>
        </Link>
        <ul id="users-nav1" className="nav-content collapse" data-bs-parent="#sidebar-nav">

        <li>
            <Link to="../productlist">
              <i className="bi bi-circle"></i><span>Product List</span>
            </Link>
        </li>            

   

         


        </ul>
      </li>
   */}

    </ul>

  </aside>
   


     
  </>
    )
}
